/* .gallery-container {
    -webkit-column-count: 3;
    -moz-column-count: 3;
    column-count: 3;
    -webkit-column-width: 33%;
    -moz-column-width: 33%;
    column-width: 33%;
    padding: 0 20px;
}

.gallery-container .pics {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    margin-bottom: 20px;
    border:solid 1px rgb(244, 243, 243)

}

.gallery-container .pics:hover {
    filter: opacity(.7);

} */
.gallery-imgs{
    text-align: center;
    margin-top: 30px;
}
.pics {
    -webkit-transition: all 350ms ease;
    transition: all 350ms ease;
    cursor: pointer;
    /* margin-bottom: 20px; */
    border:solid 1px rgb(244, 243, 243)

}
.flex_row{
    justify-content: center;
    display: flex;
    padding: 20px;
}
.thumbnail{
    padding-left: 10px;
}
.clicked{
    border: 2px solid rgb(142, 126, 126);
}
article {
    position: absolute;
    top: 0;
    left: 0;
    width: 80%;
    height: 80%;
    /* opacity: 0; */
    transition: var(--transition);
  }
/* @media(max-width:991px) {
    .gallery-container {
        -webkit-column-count: 2;
        -moz-column-count: 2;
        column-count: 2;
    }
}

@media(max-width:480px) {
    .gallery-container {
        -webkit-column-count: 1;
        -moz-column-count: 1;
        column-count: 1;
        -webkit-column-width: 100%;
        -moz-column-width: 100%;
        column-width: 100%;
    }
} */

/* model */
/* .model {
    width: 100%;
    height: 100vh;
    position: fixed;
    top: 0;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: black;
    transition: opacaity .4s ease, visibility .4s ease, transform .5s ease-in-out;
    visibility: hidden;
    opacity: 0;
    transform: scale(0);
    overflow: hidden;
    z-index: 999;
}

.model.open {
    visibility: visible;
    opacity: 1;
    transform: scale(1);
}
.model img{
    width: auto;
    max-width: 100%;
    height: auto;
    max-height: 100%;
    display: block;
    line-height: 0;
    box-sizing: border-box;
    padding: 20px 0 20px;
    margin: 0 auto;
}
.model.open i{
    position: fixed;
    top:10px;
    right:10px;
    width:2rem;
    height: 2rem;
    padding: 5px;
    background-color: rgba(0,0,0,0.4);
    color:#ffffff;
    cursor: pointer;

} */