    .navbar {
      /* background: #007AFF; */
      background: ghostwhite;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: 1.2rem;
      position: sticky !important;
      top: 0;
      z-index: 999;
    }

    .navbar-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 70px;
      max-width: 1500px;
    }

    .navbar-logo {
      /* color: #fff; */
      justify-self: start;
      margin-left: 20px;
      cursor: pointer;
      text-decoration: none;
      font-size: 2rem;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .navbar-logo:hover {
      /* color:rgb(223, 226, 229); */
      color: #1220e5;
      transition: all 0.2s ease-out;
    }

    .logo {
      height: 50px;
      width: 50px;
      /* margin: 10px; */
    }

    .logo-area {
      width: 25%;

    }

    /* .logo-area:hover{
      color:#3c6792

    } */
    .fa-typo3 {
      margin-left: 0.5rem;
      font-size: 1.8rem;
    }

    .nav-menu {
      display: grid;
      grid-template-columns: repeat(6, auto);
      grid-gap: 20px;
      list-style: none;
      text-align: center;
      width: 80vw;
      justify-content: right;
      margin-right: 2rem;
      right: 0;
    }

    .nav-item {
      height: 60px;

    }

    .nav-links {
      /* color: #fff; */
      color: #007AFF;
      display: flex;
      align-items: center;
      text-decoration: none;
      padding: 0.5rem 1rem;
      height: 100%;
    }

    .nav-links:hover {
      /* border-bottom: 4px solid #fff; */
      /* border-bottom: 4px solid #1220e5; */

      /* color:rgb(223, 226, 229); */
      color: #1220e5;
      transition: all 0.5s ease-out;
    }

    .nav-item.active {
      /* border-bottom: 4px solid #fff; */
      border-bottom: 4px solid #007AFF;

      transition: all 0.2s ease-out;
    }

    .fa-bars {
      /* color: #fff; */
      color: #007AFF;
    }

    .nav-links-mobile {
      display: none;
    }

    .menu-icon {
      display: none;
    }

    @media screen and (max-width: 960px) {
      .NavbarItems {
        position: relative;
      }

      .nav-menu {
        display: flex;
        flex-direction: column;
        width: 100%;
        height: 80vh;
        position: absolute;
        top: 80px;
        left: -100%;
        opacity: 1;
        transition: all 0.5s ease;

      }

      .nav-menu.active {
        left: 0;
        opacity: 1;
        transition: all 0.5s ease;
        z-index: 1;
        /* background:rgb(12 12 12 / 91%); */
        background: lavender;
        padding-top: 3rem;
      }

      .nav-links {
        text-align: center;
        /* padding: 2rem; */
        width: 100%;
        align-items: center;
        justify-content: center;

      }

      .nav-links:hover {
        /* background-color: #fff; */
        /* color: rgb(223, 226, 229); */
        color:#1220e5;

        border-radius: 0;
      }

      .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(25%, 30%);
      }

      .menu-icon {
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        transform: translate(-100%, 60%);
        font-size: 1.8rem;
        cursor: pointer;
      }

      .fa-times {
        /* color: #fff; */
         color:#007AFF;
        
        font-size: 2rem;
      }

      .nav-links-mobile {
        display: block;
        text-align: center;
        margin: 2rem auto;
        border-radius: 4px;
        width: 80%;
        text-decoration: none;
        font-size: 1.5rem;
        background-color: transparent;
        color: #fff;
        padding: 14px 20px;
        border: 1px solid #fff;
        transition: all 0.3s ease-out;
      }

      .nav-links-mobile:hover {
        background: #fff;
        color: #242424;
        transition: 250ms;
      }
    }

    @media screen and (max-width: 468px) {

      .navbar-logo {
        position: absolute;
        top: 0;
        left: 0;
        transform: translate(-5%, 25%);
        /* width: 80%;
        height: 50%; */
      }

      /* .logo{
        width: 50%;
        height: 80%;
      } */
    }