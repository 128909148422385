.footer-container{
    /* background-color:#f2f2f2;  */
    background-color: ghostwhite;
    padding: 4rem 0 2 rem 0;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-self: center;
    
}


.footer-subscription-text{
    margin-bottom: 24px;
    font-size: 24px;
}
.footer-input{
    padding: 8px 20px;
    border-radius: 2px;
    margin-right: 10px;
    outline: none;
    border: none;
    font-size: 18px;
    margin-bottom: 16px;
    border:1px solid #636363;;
}
.footer-links{
    width: 100%;
    /* max-width: 1000px; */
    display: flex;
    justify-content: center;
}
.footer-link-wrapper{
    display: flex;
    
}
.footer-link-items{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 30px;
    text-align: left;
    width: 50%;
    box-sizing: border-box;
    margin: 20px;
}
.footer-link-items h2{
    margin-bottom: 16px;
    font-size: 1.85rem;

}
.footer-link-items>h2{
    /* color: #636363; */
}
.footer-link-items a{
    color: black;
    text-decoration: none;
    margin-bottom: 10px;
}
.footer-link-items ul{
    /* color: #636363; */
    text-decoration: none;
    margin-bottom: 10px;
    padding: 0px;
    /* font-weight: 500; */
}
.footer-link-items a:hover{
    color: #636363;;
    transition: 0.3s ease-out;
}
.footer-email-form h2{
margin-bottom: 32px;
}
.footer-input::placeholder{
    color: #636363;
}
/* social icons */
.social-icon-link{
    color: #636363;
    font-size: 24px;
}
.social-media{
    max-width: 1000px;
    width:100%
}
.social-media-wrap{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:90%;
    max-width: 1000px;
    margin: 40px auto 0 auto;
}
.social-icons{
    display: flex;
    justify-content: space-between;
    align-items: center;
    width:240px;
}
.social-logo{
    color:#636363;
    justify-self: start;
    margin-left: 20px;
    cursor: pointer;
    text-decoration: none;
    font-size: 2rem;
    display: flex;
    align-self: center;
    margin-bottom: 16px;

}
.website-rights{
    color:#636363;
    /* margin-bottom: 16px; */
    text-align: center;
    padding: 20px;
justify-content: center;
align-items: center;

}
.website-rights {
    text-align: center;
    font-size: 14px;
    /* color: #999; */
    padding: 20px;
    background-color: #f9f9f9;
    /* position: fixed; */
    bottom: 0;
    left: 0;
    right: 0;
  }

h1,
  h2,
  h3,
  h4 {
    letter-spacing: var(--spacing);
    text-transform: capitalize;
    line-height: 1.25;
    margin-bottom: 0.75rem;
  }
  h2{
    font-size: 1.5rem;
  }

@media screen and (max-width:820px) {
    .footer-link-items>h2{
            font-size: 1.55rem;
    }
    h1 {
        font-size: 3rem;
      }
      h2 {
        font-size: 1.25rem;
      }
      h3 {
        font-size: 1.5rem;
      }
      h4 {
        font-size: 1rem;
      }
      body {
        font-size: 1rem;
      }
      h1,
      h2,
      h3,
      h4 {
        line-height: 0.75;
      }
    .footer-links{
        padding-top: 2rem;

    }
    .footer-input{
        width:100%
    }
    .btn{
        width: 100%;
    }
    .footer-link-wrapper{
        flex-direction: column;
        align-items: center;

    }
    .social-media-wrap{
        flex-direction: column;
    }
}

@media screen and (max-width:620px) {
    h2 {
        font-size: 1rem;
      }
}