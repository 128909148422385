.youtube-player-wrapper {
  position: relative;
  padding-bottom: 56.25%;
  /* 16:9 aspect ratio */
  height: 0;
  width: 100%;

  overflow: hidden;
}

.youtube-player-wrapper iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 80%;
  align-items: center;
  justify-content: center;
}

/* .about, */
.aboutText {
  margin: 0% 5% 0% 5%;
  /* margin-left: 30px; */
  align-items: center;
  justify-content: center;
  display: flex;
  text-align: justify;
}


@media screen and (max-width: 768px) {
  .aboutText {
    font-size: 16px;
  }

  .download-page h1 {
    font-size: 1.6rem;
    margin-bottom: 1rem;
    color: #fff;
  }
}