.privacy-policy {
    margin: 0 auto;
    max-width: 90%;
    font-family: Arial, sans-serif;
    padding: 10px;
  }
  
  .privacy-policy h1 {
    font-size: 34px;
    font-weight: 600;
    margin-bottom: 20px;
  }
  
  .privacy-policy h2 {
    font-size: 26px;
    font-weight: 600;
    margin-top: 35px;
    margin-bottom: 10px;
  }
  
  .privacy-policy h3 {
    font-size: 20px;
    font-weight: bold;
    margin-top: 30px;
    margin-bottom: 10px;
  }
  
  .privacy-policy p {
    font-size: 1rem;
    text-align: initial;
    padding: 5px;
    margin-bottom: 15px;

  }
  
  .privacy-policy ul {
    margin: 20px 30px;
    list-style-type: disc;
    padding-left: 20px;
  }
  
  .privacy-policy li {
    font-size: 16px;
    margin-bottom: 5px;
    line-height: 1.5;
    list-style-type: circle;
    overflow-x: initial;
    color: black;

  }
  
  .privacy-policy a {
    color: #0066cc;
    text-decoration: none;
    font-weight: 500;

    
  }
  
  .privacy-policy a:hover {
    text-decoration: underline;
  }
  
  @media screen and (max-width: 768px) {
    .privacy-policy h1 {
        font-size: 30px;
        font-weight: bold;
        /* margin: 20px; */
      }
      .privacy-policy h2 {
        /* font-size: 20px; */
        font-weight: bold;
        margin-top: 60px;
        margin: 20px;
      }
    .privacy-policy p {
        font-size: 15px;
        margin-bottom: 0px;
        line-height: 1.5;
        text-align: initial;
        padding: 5px;

      }
      .privacy-policy ul {
        margin: 20px 18px;
    
      }
      .privacy-policy li {
        font-size: 16px;
        margin-bottom: 5px;
        line-height: 1.5;
        list-style-type: circle;
        overflow-x: initial;
      }
  }