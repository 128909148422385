/* video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }
  
  .hero-container {
    background: url('/images/img-home.jpg') center center/cover no-repeat;
    height: 85vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
    padding: 20px;
  }
  
  .hero-container > h1 {
    color: #fff;
    font-size: 58px;
    margin-top: 50px; 
    font-weight: 900;
    font-family: "Montserrat";
  }
  
  .hero-container > p {
    margin-top: 28px;
    color: #fff;
    font-size: 28px;
    font-family: 'Montserrat','Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
  }
  
  .hero-btns .btn {
    margin: 6px;
  }
  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  @media screen and (max-width: 960px) {
    .hero-container > h1 {
      font-size: 70px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .hero-container > h1 {
      font-size: 50px;
      margin-top: -100px;
    }
  
    .hero-container > p {
      font-size: 30px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
    }
  
    .btn {
      width: 100%;
    }
  } */

  video {
    object-fit: cover;
    width: 100%;
    height: 100%;
    position: fixed;
    z-index: -1;
  }
  
  .hero-container {
    /* background: url('../../public/images/truck-on-road.jpg') center center/cover no-repeat; */
    background: url('../../public/images/road-desert.jpg') center center/cover no-repeat;

    height: 90vh;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    box-shadow: inset 0 0 0 1000px rgba(0, 0, 0, 0.2);
    object-fit: contain;
  }
  
  .hero-container > h2 {
    color: #fff;
    margin-left: 30px;
    font-size: 60px;
    margin-top: -100px;
  }
  
  .hero-container > p {
    margin-top: 8px;
    margin-left: 30px;

    color: #fff;
    font-size: 22px;
    font-family: 'Trebuchet MS', 'Lucida Sans Unicode', 'Lucida Grande',
      'Lucida Sans', Arial, sans-serif;
  }
  
  .hero-btns {
    margin-top: 32px;
    /* display: 'inline-block'; */

  }
  
  .hero-btns .btn {
    margin: 6px;   

  }

  
  .fa-play-circle {
    margin-left: 4px;
  }
  
  
  @media screen and (max-width: 960px) {
    .hero-container > h2 {
      font-size: 50px;
      margin-top: -150px;
    }
  }
  
  @media screen and (max-width: 768px) {
    .hero-container > h2 {
      font-size: 40px;
      margin-top: -100px;
    }
  
    .hero-container > p {
      font-size: 20px;
    }
  
    .btn-mobile {
      display: block;
      text-decoration: none;
      width: 100%;

    }
  
    .btn {
      width: 100%;
    }
  }