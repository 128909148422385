 

.download-page {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 60vh;
    /* background-color: #f5f5f5; */
    background-color: #007AFF;
    font-family: Arial, sans-serif;
  }
  
  .download-page h1 {
    font-size: 2rem;
    margin-bottom: 1rem;
    color: #fff;
  }
  
  .download-page p {
    font-size: 1.2rem;
    margin-bottom: 2rem;
    text-align: center;
    color: #fff;
  }
  
  .download-links {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .download-links a {
    display: inline-block;
    margin: 0 1rem;
    transition: transform 0.3s ease-in-out;
  }
  
  .download-links a:hover {
    transform: scale(1.1);
  }
  
  .download-links img {
    max-width: 180px;
    max-height: 85px;
    width: 170px;
    height: 85px;
  }
  