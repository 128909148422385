/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

.home,
.about
.services,
.products,
.faq,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  background-image: url('/images/img-2.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 50px;
}

.products {
  background-image: url('/images/img-1.jpg');
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 50px;
}

.sign-up {
  background-image: url('/images/img-8.jpg');
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 50px;
}

.about,
.faq{
  padding-left: 20%;
    vertical-align: top;
    width: 70%;
}

.headerTitle{
  font-size: 1.3em;
  display: flex;
   margin-top: 0.5em;
  line-height: 1.4;
  font-family: Montserrat,sans-serif;
}

.aboutText{
   font-size: 16px;
    color: #585858;
    margin: 0 0 20px;
    
  font-family: 'Montserrat','sans-serif';
}
.page-header{
font-size: 40px;
color: #000;
}

.form-control{
  width: 70%!important;
}

.contact ul{
  margin: 5px;
  padding-left: 0px;
} */

*,
::after,
::before {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  overflow-x: clip;
}
body {
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen,
    Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  background: var(--clr-grey-10);
  color: var(--clr-grey-1);
  line-height: 1.5;
  font-size: 0.875rem;
}


/* * {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Montserrat,'PT Sans', sans-serif;
} */
.form-control{
  width: 70% !important;
}
.home,
.services,
.products,
.sign-up {
  display: flex;
  height: 90vh;
  align-items: center;
  justify-content: center;
  font-size: 3rem;
}

.services {
  /* background-image: url('/images/img-2.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.products {
  background-position: center;
  background-size: fill;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}

.sign-up {
  /* background-image: url('/images/img-8.jpg'); */
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
  color: #fff;
  font-size: 100px;
}
.contact{
  display: grid;
  margin-left: 30px;
  align-items: center;
  justify-content: center;
}
.card{
  margin-left: 12%!important;
  margin-bottom: 5%;
}